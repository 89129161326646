<!--<style>
    .el-select,.el-select__tags {
        position: absolute;
        line-height: normal;
        white-space: normal;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
</style>-->

<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" @click="back()">返回</el-button>
                </p>
                <div class="h-b"><el-button type="primary" size="small" @click="addEnterpriseInfo" v-right-code="'Optenterprise:Save'">新增</el-button> </div>
                <div class="h-b">
                    <el-button type="text" size="small" @click="saveEnterpriseInfo" v-right-code="'Optenterprise:Save'">保存</el-button>
                </div>
            </div>
        </div>
        <el-form ref="_enterpriseInfoForm" :model="dataSource" :rules="enterpriseInfoCheckRule">
            <el-tabs class="done" type="border-card" v-model="activeName" @tab-click="tab1Click">
                <el-tab-pane label="基本信息" name="tabEnterpriseInfo">
                    <div class="form-list">
                        <el-form-item>
                            <div :hidden="!isDisabled">
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>客户编号：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="EnterpriseCode">
                                        <el-input v-model="dataSource.EnterpriseCode" :maxlength="30" :minlength="4"
                                                  :disabled="true" placeholder="客户编号"></el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>客户名称：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="EnterpriseName">
                                    <el-input v-model="dataSource.EnterpriseName" :maxlength="30" :minlength="4"
                                              placeholder="客户名称"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>地区：
                            </el-col>
                            <el-col :span="9">
                                <Distpicker :placeholders="dataSource"></Distpicker>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="EnterpriseAddress">
                                    <el-input v-model="dataSource.EnterpriseAddress" :maxlength="200"
                                              placeholder="详细地址"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>联系人：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="EntContactName">
                                    <el-input v-model="dataSource.EntContactName" :maxlength="30" :minlength="4"
                                              placeholder="联系人"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>联系电话：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="EntContactPhone">
                                    <el-input v-model="dataSource.EntContactPhone" :maxlength="30" :minlength="4"
                                              placeholder="联系电话"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>状态：</el-col>
                            <el-col :span="7">
                                <el-radio-group v-model="dataSource.EnterpriseStatus">
                                    <el-radio :label="100">启用</el-radio>
                                    <el-radio :label="200">停用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-form-item>
                        <!-- <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>解密：</el-col>
                            <el-col :span="7" class="from-item-left">
                                <el-form-item>
                                    <el-checkbox v-model="dataSource.IsDecrypt">下发订单自动解密</el-checkbox>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>主数据：</el-col>
                            <el-col :span="7" class="from-item-left">
                                <el-form-item>
                                    <el-checkbox v-model="dataSource.IsSyncData">主数据自动同步</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>用户登录：</el-col>
                            <el-col :span="7" class="from-item-left">
                                <el-form-item  prop="NeedSecondConfirm">
                                    <el-checkbox v-model="dataSource.NeedSecondConfirm">开启用户登录系统双安全验证</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-form-item> -->
                        <el-form-item>
                            <el-col :span="3" class="form-title">备注：</el-col>
                            <el-col :span="17">
                                <el-form-item prop="Remark">
                                    <el-input type="textarea" v-model="dataSource.Remark" :maxlength="500"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="租户信息" name="tabTenantInfo">
                    <div class="form-list">
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>租户域名：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="DomainName">
                                    <el-input v-model="dataSource.DomainName" :maxlength="30" :minlength="4"
                                        :disabled="isDisabled" placeholder="租户域名"></el-input>
                                </el-form-item>
                            </el-col>
                            <div>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>管理员账号：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="UserName">
                                        <el-input v-model="dataSource.UserName" :maxlength="30" :minlength="4"
                                            :disabled="true" placeholder="管理员账号"></el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <el-col :span="1" class="form-title" :hidden="!isDisabled">
                                <el-button @click="showReset" style="padding: 0 4px;"
                                    v-right-code="'Manage_Right_Commerce_Enterprise_ResetPwd'" size="small" type="text">
                                    重置密码</el-button>
                            </el-col>
                            <el-col :span="1" class="form-title" :hidden="dataSource.EnterpriseUserID">
                                <el-button @click="initUser" style="padding: 0 4px;" size="small" type="text">
                                    初始化账号</el-button>
                            </el-col>
                        </el-form-item>
                        <el-form-item :hidden="isDisabled">
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>登录密码：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="UserPassword">
                                    <el-input type="password" v-model="dataSource.UserPassword" :maxlength="30"
                                        :minlength="4" :disabled="isDisabled" placeholder="登录密码"></el-input>
                                </el-form-item>
                            </el-col>
                            <div>
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>确认密码：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="UserPasswordConfirm">
                                        <el-input type="password" v-model="dataSource.UserPasswordConfirm"
                                            :maxlength="30" :minlength="4" placeholder="确认密码"></el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                        </el-form-item>
                        <!--<el-form-item>
                <el-col :span="3" class="form-title">用户数量上限：</el-col>
                <el-col :span="7">
                    <el-form-item prop="UserUpLimit">
                        <el-input v-model="dataSource.UserUpLimit" :maxlength="30" :minlength="4" placeholder="不填则无限制"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="3" class="form-title">货主数量上限：</el-col>
                <el-col :span="7">
                    <el-form-item prop="ConsignorUpLimit">
                        <el-input v-model="dataSource.ConsignorUpLimit" :maxlength="30" :minlength="4" placeholder="不填则无限制"></el-input>
                    </el-form-item>
                </el-col>
            </el-form-item>-->
                        <el-form-item>
                            <el-col :span="3" class="form-title">失效日期：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ExpirationDate">
                                    <el-date-picker v-model="dataSource.ExpirationDate" type="date" placeholder="失效日期"
                                        :picker-options="pickerOptions">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </div>
                </el-tab-pane>
                <!-- <el-tab-pane label="密码策略" name="tabPasswordConfig">
                    <div class="form-list">
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>密码策略：</el-col>
                            <el-col :span="7" class="from-item-left">
                                <el-form-item  prop="NeedPasswordConfig">
                                    <el-checkbox v-model="dataSource.NeedPasswordConfig">开启用户登录密码策略</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">密码长度限制：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="PasswordLengthLowerLimit">
                                    <el-input-number v-model="dataSource.PasswordConfig.PasswordLengthLowerLimit" :min="8" :max="20"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">最长使用期限（天）：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="PasswordMostUseDays">
                                    <el-input-number v-model="dataSource.PasswordConfig.PasswordMostUseDays" :min="1" :max="3650"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">密码组成：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="PasswordComposeMethod">
                                    <el-select v-model="dataSource.PasswordConfig.PasswordComposeMethod" placeholder="请选择">
                                        <el-option v-for="item in pwdOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                      </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">允许登录失败次数：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="PasswordRetryCount">
                                    <el-input-number v-model="dataSource.PasswordConfig.PasswordRetryCount" :min="3" :max="365"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">必须使用其他密码的次数：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="PasswordReUseCount">
                                    <el-input-number v-model="dataSource.PasswordConfig.PasswordReUseCount" :min="0" :max="365"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">闲置超时时间（分钟）：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="PasswordIdleTimeoutMinutes">
                                    <el-input-number v-model="dataSource.PasswordConfig.PasswordIdleTimeoutMinutes" :min="1" :max="1440"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">密码锁定时间（分钟）：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="PasswordLockMinutes">
                                    <el-input-number v-model="dataSource.PasswordConfig.PasswordLockMinutes" :min="1" :max="1440"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">是否使用一次性密码：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="PasswordAllowDefault">
                                    <el-switch v-model="dataSource.PasswordConfig.PasswordAllowDefault" active-color="#13ce66" inactive-color="#ff4949">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </div>
                </el-tab-pane> -->
                <el-tab-pane label="相关配置" name="industryInfo">
                    <div class="form-list">
                        <el-form-item>
                            <el-col :span="3" class="form-title">数据库类型：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="TenantMultiDBType">
                                    <DictionarySelect 
                                        v-model="dataSource.TenantMultiDBType" 
                                        datType='Int' 
                                        dicTypeCode="TenantMultiDBTypeCode">
                                    </DictionarySelect>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">所属行业：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="IndustryType">
                                    <DictionarySelect 
                                        v-model="dataSource.IndustryType" 
                                        datType='Int' 
                                        dicTypeCode="IndustryTypeCode"
                                        @change="industryChange">
                                    </DictionarySelect>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    
                    <el-tabs v-if="false" type="border-card" v-model.trim="activeName2" class="tab-container">
                        <el-tab-pane label="资源配置" name="tab1">
                            <div class="h-table-list menulist">
                                <div class="h-scroll">
                                    <div class="table-s">
                                        <el-table border highlight-current-row :default-expand-all='isExpand' ref="tableMenu" row-key="MenuId"
                                            :tree-props="{ children: 'Children', hasChildren: 'hasChildren' }"
                                            style="width: 100%;margin-bottom: 20px;" :data="dataSource.MenuList || []" 
                                            @select="tableSelect"
                                            @select-all="tableSelectAll"
                                            @selection-change="selectionChange">
                                            <el-table-column label="菜单/权限">
                                                <template slot-scope="scope">
                                                    <span class="menuCls" v-if="scope.row['MenuType']=='1'">菜单</span>
                                                    <span class="permCls" v-else>权限</span>
                                                    <span>&nbsp;&nbsp;</span>
                                                    <span>{{ scope.row['MenuName'] }}</span>
                                                </template>
                                            </el-table-column>
                                            <!-- <el-table-column label="菜单类型">
                                                <template slot-scope="scope">
                                                    <span class="menuCls" v-if="scope.row['MenuType']=='1'">菜单</span>
                                                    <span class="permCls" v-else>权限</span>
                                                </template>
                                            </el-table-column> -->
                                            <!-- <el-table-column label="自定义名">
                                                <template slot-scope="scope">
                                                    <el-input v-model="scope.row['MenuName']" />
                                                </template>
                                            </el-table-column> -->
                                            <el-table-column type="selection"></el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="商品字段配置" name="tab2"></el-tab-pane>
                        <el-tab-pane label="订单字段配置" name="tab3"></el-tab-pane> -->
                    </el-tabs>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <!--重置密码-->
        <DialogEx :options="CustomerOptions" title="重置密码" @onOk="resetPwd_onOk" style="width:70%;margin-left:15%;">
            <ResetPwd ref="resetCustomerPwd" :OptEnterpriseID="OptEnterpriseID" :EnterpriseName="EnterpriseName">
            </ResetPwd>
            <template slot="ButtonGroupLeft">
                <el-button type="primary" @click="resetPwdForm">清空</el-button>
            </template>
        </DialogEx>
    </div>

</template>
<style lang="scss">
    .fixed-h {
        height: 30px;

        .el-input {
            height: 30px;

            .el-input__inner {
                height: 100% !important;
            }
        }

        .el-select__tags {
            height: calc(100% - 6px) !important;
            flex-wrap: wrap;
            overflow-y: auto;
        }
    }
</style>
<script>
    export default {
        data() {
            var checkEnterpriseName = function (rule, value, callback) {
                if (!value) return callback(new Error('客户名称不能为空'));
                callback();
            };
            var checkEnterpriseAddress = function (rule, value, callback) {
                if (!this.isDisabled) {
                    if (!value) return callback(new Error('所在地址不能为空'));
                }
                callback();
            };
            var checkEntContactName = function (rule, value, callback) {
                if (!this.isDisabled) {
                    if (!value) return callback(new Error('联系人不能为空'));
                }
                callback();
            };
            var checkEntContactPhone = function (rule, value, callback) {
                var phoneReg = /^1[3-578]\d{9}$/;
                if (!this.isDisabled) {
                    if (!value) return callback(new Error('联系电话不能为空'));
                    else if (!phoneReg.test(value)) return callback(new Error('联系电话格式不正确'));
                }
                callback();
            };
            var checkDomainName = function (rule, value, callback) {
                if (!value) return callback(new Error('租户域名不能为空'));
                callback();
            };
            return {
                isExpand:false,
                menuDataSource:[],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now();
                    }
                },
                ContactPersons: [],
                multipleSelection: [],
                tab2activeName: 'taborderitem',
                isDisabled: false,
                activeName: 'tabEnterpriseInfo',
                activeName1: 'tabproductinfo',
                activeName2: 'tab1',
                enterpriseInfoCheckRule: {
                    EnterpriseName: [{ validator: this.checkEnterpriseName, trigger: 'blur' }],
                    EnterpriseAddress: [{ validator: checkEnterpriseAddress, trigger: 'blur' }],
                    EntContactName: [{ validator: checkEntContactName, trigger: 'blur' }],
                    EntContactPhone: [{ validator: checkEntContactPhone, trigger: 'blur' }],
                    //DomainName: [{ validator: this.checkDomainName, trigger: 'blur' }],
                    UserPassword: [{ validator: this.checkUserPassword, trigger: 'blur' }]
                },
                CancalProductInfo: [],
                EnterpriseName: '',
                OptEnterpriseID: '',
                CustomerOptions: {
                    visible: false
                },
                configDisabled: true,
                dbDisabled: true,
                dealOptions: [],
                invOptions: [],
                inOptions: [],
                outOptions: [],
                pwdOptions: [
                    { value: 0, label: '不限制' },
                    { value: 1, label: '数字+字母' },
                    { value: 2, label: '数字+字母+特殊字符' },
                ],
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            dataSource: {
                MemberProductList: []
            },
            dbType: {}
        },
        mounted() {
            this.Event.$on("clearEditEnterpriseInfoForm", () => this.resetForm);
            this.GetOptionLists();
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled = this.Utils.emptyGuid != this.dataSource.OptEnterpriseID;
                    if (this.dataSource.EnterpriseCode) {
                        this.dbDisabled = true;
                    } else {
                        this.dbDisabled = false;
                    }
                },
                deep: true
            },
            'dataSource.OptEnterpriseID':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal){
                            this.$nextTick(() => {
                                //this.initialTable();
                            })
                        } 
                        //this.initialMenu(curVal); //初始化
                    }
                },
                deep: true
            },
        },
        created: function () {
            this.Event.$on("clearForm", this.resetForm);
        },
        methods: {
            checkEnterpriseName(rule, value, callback) {
                if (!value) return callback(new Error('客户名称不能为空'));
                else {
                    this.$ajax.send("omsapi/optenterprise/checkenterprisenameexist", "post", this.dataSource, (data) => {
                        if (data.OperationDesc == "客户名称已存在") {
                            return callback(new Error('客户名称已存在'));
                        }
                    });
                }
                callback();
            },
            checkDomainName(rule, value, callback) {
                if (!value) return callback(new Error('租户域名不能为空'));
                if (this.Utils.isChinese(this.dataSource.DomainName))
                    return callback(new Error('域名不能输入汉字'));
                else {
                    this.$ajax.send("omsapi/optenterprise/checkdomainnameexist", "post", this.dataSource, (data) => {
                        if (data.OperationDesc == "域名已存在") {
                            return callback(new Error('域名已存在'));
                        }
                    });
                }
                callback();
            },
            GetOptionLists: function () {
                var _this = this;
                //获取平台类型
                _this.$ajax.send("omsapi/optenterprise/GetSysDicListByCode", "get", { id: 900 }, (data) => {
                    if (data.IsSuccess) {
                        _this.dealOptions = data.Result;
                    }
                    else {
                        _this.Utils.messageBox("获取平台类型列表失败.", "warning");
                    }
                });
                //获取库存类型
                _this.$ajax.send("omsapi/optenterprise/GetSysDicListByCode", "get", { id: 800 }, (data) => {
                    if (data.IsSuccess) {
                        _this.invOptions = data.Result;
                    }
                    else {
                        _this.Utils.messageBox("获取平台类型列表失败.", "warning");
                    }
                });
                //获取入库订单类型
                _this.$ajax.send("omsapi/optenterprise/GetSysDicListByCode", "get", { id: 710 }, (data) => {
                    if (data.IsSuccess) {
                        _this.inOptions = data.Result;
                    }
                    else {
                        _this.Utils.messageBox("获取平台类型列表失败.", "warning");
                    }
                });
                //获取出库订单类型
                _this.$ajax.send("omsapi/optenterprise/GetSysDicListByCode", "get", { id: 720 }, (data) => {
                    if (data.IsSuccess) {
                        _this.outOptions = data.Result;
                    }
                    else {
                        _this.Utils.messageBox("获取平台类型列表失败.", "warning");
                    }
                });
            },
            checkUserPassword(rule, value, callback) {
                if (!this.isDisabled) {
                    if (value === '' || !value) return callback(new Error('密码不能为空'))
                    else {
                        if (value.length < 8) {
                            return callback(new Error('密码至少八位或者八位以上'))
                        }
                        var patrn = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
                        if (!patrn.exec(value)) return callback(new Error('密码必须包含数字，字母，特殊字符'))
                    }
                }
                callback();
            },
            showReset: function () {
                this.CustomerOptions.visible = true;
                this.EnterpriseName = this.dataSource.EnterpriseName;
                this.OptEnterpriseID = this.dataSource.OptEnterpriseID;
                this.$refs.resetCustomerPwd.resetForm();

            },
            initUser:function () {
                var _this=this;
                _this.$ajax.send("omsapi/optenterprise/initEnterpriseUser", "post", {OptEnterpriseID:_this.dataSource.OptEnterpriseID}, (data) => {
                    if (data.IsSuccess) {
                        _this.Utils.messageBox("初始化用户成功.", "success");
                        _this.dataSource.EnterpriseUserID=data.Result;
                    }
                    else {
                        _this.Utils.messageBox(data.OperationDesc, "warning");
                    }
                });
            },
            resetPwd_onOk(options) {
                this.$refs.resetCustomerPwd.resetPwd(options);
            },
            resetPwdForm() {
                this.$refs.resetCustomerPwd.resetForm();
            },
            
            tab1Click: function (tab, event) {
                console.log(tab);
                if (tab.name == "tabEnterpriseInfo") {
                    this.configDisabled = true;
                }
                else {
                    this.configDisabled = false;
                }
            },
            back() {
                this.$parent.checkSaveStatus();
            },
            addEnterpriseInfo() {
                this.Event.$emit("onAddEnterpriseInfo");
            },
            resetForm: function (memberId) {
                !this.isPassValidate && this.$refs['_enterpriseInfoForm'].resetFields();//清空表单
            },
            saveEnterpriseInfo() {
                var _this = this;
                if (!_this.dataSource.CityName || !_this.dataSource.CountyName || !_this.dataSource.ProvinceName) {
                    _this.Utils.messageBox("地区信息不全.", "error");
                    return false;
                }
                if (!this.isDisabled && this.dataSource.UserPassword !== this.dataSource.UserPasswordConfirm) {
                    this.Utils.messageBox("两次输入的账号密码不一致.", "error");
                    return;
                }
                if (this.Utils.isChinese(this.dataSource.DomainName)) {
                    this.Utils.messageBox("域名不能输入汉字.", "error");
                    return;
                }

                _this.$refs["_enterpriseInfoForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    if (valid) {
                        var routeName = _this.dataSource.OptEnterpriseID === _this.Utils.emptyGuid ? "add" : "save";
                        this.$ajax.send("omsapi/optenterprise/" + routeName, "post", _this.dataSource, (data) => {
                            _this.$parent.syncDataSource();
                            _this.Event.$emit("reloadEnterpriseInfoPageList", data.Result);
                            _this.Utils.messageBox("保存成功.", "success");
                            _this.dataSource.dbDisabled = true;
                        });
                    } else {
                        return false;
                    }
                });
            }
            ,
            addSysDBConfig: function () {
                var _this = this;
                var newRow = { Platform: null, DBIP: null, DBPort: null, DBUser: null, DBPwd: null };
                _this.dataSource.SysDBConfigList.push(newRow);
            },
            removeSysDBConfig: function (row, index) {
                this.dataSource.SysDBConfigList.remove(row);
            },
            setChildren (children, type) {
                // 编辑多个子层级
                children.map((j) => {
                    this.toggleSelection(j, type);
                    j.IsVisible = type;
                    if (j.Children && j.Children.length > 0) {
                    this.setChildren(j.Children, type)
                    }
                })
            },
            toggleSelection (row, select) {
                if (row) {
                    this.$nextTick(() => {
                    this.$refs.tableMenu && this.$refs.tableMenu.toggleRowSelection(row, select)
                    })
                }
            },
            tableSelect (selection, row) {
                if (selection.some((el) => { return row.MenuId === el.MenuId })) {
                    row.IsVisible = true;
                    if (row.Children) {
                    this.setChildren(row.Children, true)
                    }
                } else {
                    row.IsVisible = false;
                    if (row.Children) {
                    this.setChildren(row.Children, false)
                    }
                }
            },
            tableSelectAll (selection) {
                // tabledata第一层只要有在selection里面就是全选
                var data = this.$refs.tableMenu.data;
                const isSelect = selection.some((el) => {
                    const tableDataIds = data.map((j) => j.MenuId)
                    return tableDataIds.includes(el.MenuId)
                })
                // tableDate第一层只要有不在selection里面就是全不选
                const isCancel = !data.every((el) => {
                    const selectIds = selection.map((j) => j.MenuId)
                    return selectIds.includes(el.MenuId)
                })
                if (isSelect) {
                    selection.map((el) => {
                        el.IsVisible = true;
                        if (el.Children) {
                            // 解决子组件没有被勾选到
                            this.setChildren(el.Children, true)
                        }
                    })
                }
                if (isCancel) {
                    data.map((el) => {
                        el.IsVisible = false;
                        if (el.Children) {
                            // 解决子组件没有被勾选到
                            this.setChildren(el.Children, false)
                        }
                    })
                }
            },
            selectionChange (selection) {
                this.multipleSelection = selection;
                this.$emit('selectionChange', selection);
            },
            initialTable(){
                var data = this.$refs.tableMenu.data;
                data.map((el) => {
                    if(el.IsVisible)
                        this.$refs.tableMenu.toggleRowSelection(el, el.IsVisible);
                    if (el.Children) {
                        this.initialChild(el.Children)
                    }
                })
            },
            initialChild(list){
                list.map((el) => {
                    if(el.IsVisible)
                        this.$refs.tableMenu.toggleRowSelection(el, el.IsVisible);
                    if (el.Children) {
                        this.initialChild(el.Children)
                    }
                })
            },
            industryChange(val){
                //this.initialMenu(val);
            },
            initialMenu(type){
                var _this = this;
                _this.$ajax.send("omsapi/sysindustry/getbyindustrytype", "get", { industryType: type }, (data) => {
                    _this.dataSource.MenuList = data.Result.MenuList;
                    _this.$nextTick(() => {
                        _this.initialTable();
                    })
                });
            },
        },
        components: {
            "ResetPwd": resolve => { require(['./resetpwd.vue'], resolve) },
        }

    }

</script>
<style>
  .h-table-list.menulist .menuCls{background-color: #169bd5; color: #ffffff; padding: 5px; border-radius: 3px;}
  .h-table-list.menulist .permCls{background-color: #f59a23; color: #ffffff; padding: 5px; border-radius: 3px;}
  .h-table-list.menulist .el-table tbody tr.el-table__row td.el-table__cell{ text-align: left !important;}
</style>